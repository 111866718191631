'use strict';

jQuery.noConflict();

jQuery( document ).ready( function( $ ) {

	var siteInit = {

		DOMready: function() {

			if ( $('.js-slider--primary').length > 0 ) {
				siteInit.slider();
			}
			if ( $('.js-carousel').length > 0 ) {
				siteInit.carousel();
			}

			if ( $('[href$=".jpg"], [href$=".png"], [href$=".gif"]').length > 0 ) {
				siteInit.modal();
			}

			if ( $('.js-navicon').length > 0 ) {
				siteInit.mobileMenu();
			}
			if ( $('.js-toggle').length > 0 ) {
				siteInit.toggleSections();
			}

			if ( $('.js-sticky').length > 0 ) {

				$(".js-sticky").sticky({topSpacing:48});

				$('.js-sticky').on('sticky-start', function() {
					$(this).find('.form-holder').addClass('is-compact');
				});

				$('.js-sticky').on('sticky-end', function() {
					$(this).find('.form-holder').removeClass('is-compact');
				});

				$('.reveal-form-button').on('click', function(e) {
					$('#form-in-page').find('.form-holder').removeClass('is-compact');
					$('.js-sticky').unstick();
					$('#form-in-page').find('.sticker').removeClass('js-sticky')
					$(this).hide();
					$(window).scrollTo($('#form-in-page'), 800, {axis:'y'});

				});

			}

			if ( $('.js-scrollTo').length > 0 ) {

				$('.js-scrollTo').on('click', function(e) {
					var target = $(this).attr('href');
					console.log($(target));
					$(window).scrollTo($(target), 800, {axis:'y'});
					e.preventDefault();
				});

			}

			if ( $('#map').length > 0 ) {
				siteInit.map();
			}
		},

		// Filtri della pagina progetti
		filterProjects: function() {
			$('#filter').on('click', '.js-filter', function() {
				var active = $(this).data('filter');
				$('.js-filter').each(function(){
					if ( $(this).hasClass('filter__item--active') ) {
						$(this).removeClass('filter__item--active');
					}
				});

				$(this).addClass('filter__item--active');

				$('#projects').find('.js-filter-target').each(function() {

					if ( $(this).data('location') !== active ) {
						$(this).find('.card').addClass('card--hidden');
					} else {
						$(this).find('.card').removeClass('card--hidden');
					}

					if ( active === 'all' ) {
						console.log($(this));
						$(this).find('.card').removeClass('card--hidden');
					}

				});
			});
		},

		// Slider
		slider: function() {

			$(".js-slider--primary").slick({
				dots: true,
				speed: 500
			});

		},
		carousel: function() {

			$(".js-carousel").slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true
			});

		},

		// Modal
		modal: function() {

			$('[href$=".jpg"], [href$=".png"], [href$=".gif"]').colorbox({
				transition: 'elastic',
				speed: 400,
				opacity: 0.8,
				slideshow: true,
				slideshowSpeed: 4000,
				itemsnitialWidth: 50,
				initialHeight: 50,
				maxWidth: '90%',
				maxHeight: '90%',
			});

		},

		map: function() {



		},

		// Mobile menu
		mobileMenu: function() {

			$('body').on( 'click', '.js-navicon', function() {
				$(this).toggleClass('navicon--active');
				$('.navigation').toggleClass('navigation--open');
			});

		},

		toggleSections: function() {
			$('body').on('click', '.js-toggler',  function(e) {
				var target = $(this).attr('href');
				$(this).toggleClass('is-active').parents('.js-toggle').find('.js-toggle-element').toggleClass('hidden');				e.preventDefault();
			})
		}

	};

	siteInit.DOMready();

});


function initMap() {

	var mapOptions = {
		// How zoomed in you want the map to start at (always required)
		zoom: 18,

		// The latitude and longitude to center the map (always required)
		center: new google.maps.LatLng(45.529218, 10.189199), // New York

		// How you would like to style the map.
		// This is where you would paste any style found on Snazzy Maps.
		styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
	};

    // Get the HTML DOM element that will contain your map
    // We are using a div with id="map" seen below in the <body>
    var mapElement = document.getElementById('map');

	// Create the Google Map using our element and options defined above
	var map = new google.maps.Map(mapElement, mapOptions);
	// Let's also add a marker while we're at it
	var marker = new google.maps.Marker({
		position: new google.maps.LatLng(45.529218, 10.189199),
		map: map
	});
}
